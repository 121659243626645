

.main_secure_page {
    padding: 50px 10px;
    font-family: 'Inter', sans-serif;
}

.se_head {
    color: white;
    font-size: 4rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}

.se_para {
    color: #fff;
    font-size: 16px;
    position: relative;
    font-family: 'Inter', sans-serif;

    text-align: left;
}

.se_para::after {
    position: absolute;
    content: "";
    background-color: #0074FF;
    height: 100%;
    width: 3px;
    left: -4%;
    top: 0px;
}

.black_box h1 {
    color: #BB0B0B;
    font-weight: 700;
    font-family: 'Inter', sans-serif;

}

.black_box p {
    color: #fff;
    font-family: 'Inter', sans-serif;

}

.black_box {
    background: linear-gradient(to left, #010514, #000) !important;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    /* background: red !important  ; */
}



@media screen and (max-width:600px) {
    .se_head {
        color: white;
        font-size: 1.5rem;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
    }

    .resvers {
        background: linear-gradient(to bottom, #181818, #000) !important;
        margin-top: 2rem;
    }
}

@media screen and (max-width:993px) {
    .se_head {
        color: white;
        font-size: 1.9rem;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
    }
}