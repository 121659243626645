.main_buy_eth{
    padding: 4rem 10px;
}



.main_heading_buy{
    color: #1199FA;
    /* background: linear-gradient(90deg, rgb(138 8 120) 10%, rgb(29 1 37) 60%) text transparent; */
    /* -webkit-text-fill-color: transparent; */
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    font-family: 'Inter', sans-serif;
    /* font-family: 'Pacifico', cursive; */

}



.buy_main_box_here {
    background: radial-gradient(276.49% 100% at 50.11% 100.00%, rgba(17, 153, 250, 0.40) 0%, rgba(17, 153, 250, 0.00) 100%),linear-gradient(0deg, rgba(1, 1, 24, 0.40) 0%, rgba(1, 1, 24, 0.40) 100%),#002F61;
    backdrop-filter: blur(5px);
    opacity: 0.9;
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    z-index: 1;
    height: 99%;
  }
  
  
  

.buy_main_box_here h4 {
    color: #1199FA;
    font-size: 30px;
    font-weight: 400;
    /* font-family: 'Signika', sans-serif; */
    font-family: 'Inter', sans-serif;
    text-align: center;

}


.buy_ul{
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 200;
    font-family: 'Inter', sans-serif;
    text-align: left;
    list-style-type: disc;
}

.site_main_buttn{
    background-color: #ffffff;
    color: black;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    justify-content: center;
    border: none;
    padding: 0.75rem 3rem; 
    text-transform: uppercase;
}


@media only screen and (max-width:600px){
    .main_heading_buy {
        font-size: 2rem;
    }
}