.main_token_staking_page{
    /* background-image: url('../Assets/bg5.png'); */
    /* background-position:center; */
    padding: 40px 0px;
}
.token_staking_box{
    background: radial-gradient(276.49% 100% at 50.11% 100.00%, rgba(17, 153, 250, 0.40) 0%, rgba(17, 153, 250, 0.00) 100%),linear-gradient(0deg, rgba(1, 1, 24, 0.40) 0%, rgba(1, 1, 24, 0.40) 100%),#002F61;
    /* opacity: 0.9; */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
    padding: 20px;
    position: relative;
    z-index: 3 !important;
    
}
.lef_token_stk_co h1 {
    /* text-align: center; */
    color: #BB0B0B !important;
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.toatal_Stack p {
    color: white;
    margin-bottom: 0px;
}

.plans_box {
    background: #14202E;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
}

.inner_days_{
    font-family: 'Inter', sans-serif;

    font-size: 18px;
    text-align: center;
    color: white !important;
/* background-color: ; */
border-radius: 10px;
padding: 10px 20px;
cursor: pointer;
}


.plan_text {
    color:white;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 0;


}
.plan_eng{
    margin-bottom: 0px;
}
.token_mart_sawap{
    background:#0a0a0a60;
    /* opacity: 0.9; */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
    padding: 20px;

}

.mart_logo_wap {
    font-size: 18px;
    color: white !important;
    font-weight: bold;
}

.apy_rates  h1{
    font-size: 22px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;

    color: white !important;

}

.swap_input_b {
    background-color: #14202E;
    padding: 5px 0px;
    border-top-left-radius:10px ;
    border-bottom-left-radius:10px ;
}

.wap_iiinnn {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 10px 15px;
    width: 80%;
    color: white;
}


.swap_input_b button {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    padding: 0px 15px;
}


.swap_clr_btn{
    background-color: #BB0B0B;
    border: none;
    border-radius: 10px;
    color: white;
}

.token_ts {
    position: absolute;
    top: 60%;
    width: 25%;
    left:40px;
}


@media only screen and (max-width:600px){
    .inner_days_{
        padding: 10px 8px;
    }
}