.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  border-radius: 34px !important;
  color: #fff !important;
  font-size: 16px;

  text-transform: uppercase;
  background-color: #1199FA !important;
}



.MuiTabs-flexContainer {
  border: 2px solid #fff;
  border-radius: 50pc;
  display: flex !important;
  justify-content: center !important;
  /* left: 27.5rem; */
  margin: 1rem 0 !important;
  /* padding: 5px ; */
  /* position: relative; */
  margin: auto !important;
  width: max-content !important;
}
.css-orq8zk
{
    /* width: max-content !important; */
    border-radius: 50pc !important;
    display: flex !important;
    margin: auto!important;
    padding: 0!important;
}


.card_adjust {
  width: 19rem !important;
}

.tryhere {
  /* border: 2px solid red !important; */
  display: flex !important;
  justify-content: center !important;
  margin: 1rem 0;
}

.css-k008qs {
  justify-content: center !important;
}







.css-1q2h7u5 {
  color: white !important;
}

.css-1q2h7u5.Mui-selected {
  color: #fff !important;
  font-size: 22px;

  background: #1199FA !important;
  border-radius:34px !important;
  text-transform: uppercase;
}



.css-ttwr4n {
  display: none !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background-color: transparent !important;
  color: white !important;
  /* border: 2px solid red !important; */
}

.css-1aquho2-MuiTabs-indicator {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  

 
  
  .MuiTabs-flexContainer {
    width: 90%;
    position: static !important;
    margin: 1rem 0px;
  }
 
}



@media only screen and (min-width:601px) and (max-width:999px){
    .card_adjust {
        width: 14rem !important;
    }
}