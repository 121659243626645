/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


.main_transcaion_page {
  padding: 70px 10px;
  background-image: url('../Assets/bg4.png');
  background-repeat: no-repeat;
  background-position: 80%;
  background-size: 30%;
  font-family: 'Inter', sans-serif !important;
}


.trans_conent h6 {
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
}

.trans_conent h1 {
  color: white;
  font-size: 2.5rem;
  font-family: 'Inter', sans-serif;
}

.trns_box_content {
  /* background: #2E2E2E; */
  background: radial-gradient(276.49% 100% at 50.11% 100.00%, rgba(17, 153, 250, 0.40) 0%, rgba(17, 153, 250, 0.00) 100%),linear-gradient(0deg, rgba(1, 1, 24, 0.40) 0%, rgba(1, 1, 24, 0.40) 100%),#002F61;
  backdrop-filter: blur(5px);
  opacity: 0.9;
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  z-index: 1;
  height: 99%;
}

.trns_box_content h2{
  font-family: 'Inter', sans-serif;
  color: #fff;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.trns_box_content p {
  font-size: 16px;
  z-index: 10;
  font-family: 'Inter', sans-serif;
  font-weight: 100;
  color: #fff;
}


.trns_box_content h6 {
  font-size: 1.2rem;
  z-index: 10;
  font-family: 'Inter', sans-serif;
  color: #fff;
  font-weight: 400;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.trans_sc {
  position: relative;
}

.card_first_img{
  display: flex;
  justify-content: end;
}
.card_first_img img{
  width: 30%;
}
.arrow_group{
  display: flex;
  /* justify-content: center; */
margin-left: 2rem;
}

.box_arrow{
  border: 1px solid #0074FF;
  padding: 10px;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}